@import "../../styles/Mixins.module.scss";

.wrapper {
  @include pageWrapper;
  padding: 10px 20px;
}

.title {
  @include pageTitle;
}

.header {
  @include generalMargin;
  display: flex;
  justify-content: space-between;
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
  @include generalMargin;
  gap: 32px;
}

.tableSupplierPaymentMethodsWrapper {
  @include generalWrapper;
  height: 100%;
  width: 100%;
}

.newButton {
  @include greenButon;
}

.tableSupplierPaymentMethods {
  padding: 10px;
}

.newSupplierPaymentMethodWrapper {
  @include generalWrapper;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 400px;
}

.formNewSupplierPaymentMethod {
  padding: 10px;
}

.formTitle {
  @include wrapperTitle;
}

.editInfoItem {
  display: inline;
}

.inputNumber {
  width: 100%;
}

.addButton {
  margin-top: 10px;
  width: 100%;
}

@media only screen and (max-width: 812px) {
  .tableSupplierPaymentMethods {
    width: 100%;
    overflow: auto;
  }

  .tableSupplierPaymentMethodsWrapper {
    max-width: 100%;
    min-width: 100%;
    margin: 0;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
  }

  .newSupplierPaymentMethodWrapper {
    width: 100%;
    margin-top: 15px;
  }

  .formNewSupplierPaymentMethod {
    width: 250px;
  }
}

.actionButtonMore {
  @include actionButtonMore;
}

.actionIconMore {
  @include actionIconMore;
}
