@import "../../styles/Mixins.module.scss";

.wrapper {
  @include pageWrapper;
}

.contentWrapper {
  @include generalMargin;
  gap: 32px;
  display: flex;
  justify-content: space-between;

  @include md {
    flex-direction: column;
  }
}

.filtersWrapper {
  display: flex;
  margin: 10px;
  gap: 4px;

  @include md {
    flex-direction: column;
  }
}

.filterSelect {
  width: 200px;
  @include generalSelectFilters;
}

.filterInput {
  @include inputFilter;
}

.tableAttributesWrapper {
  @include generalWrapper;
  height: 100%;
  width: 100%;
}

.actionButtonMore {
  @include actionButtonMore;
}

.actionIconMore {
  @include actionIconMore;
}


.addButton {
  margin-top: 10px;
  width: 100%;
}

.editInfoItem {
  display: inline;
}

.tableAttributes {
  padding: 10px;
}

.formNewAttribute {
  padding: 10px;
  width: 100%;
}


.actionButtonsWrapper {
  display: flex;
}

.title {
  @include pageTitle;
}

.newButton {
  margin: 0 15px;
  @include greenButon;
}
.header {
  @include generalMargin;
  display: flex;
  justify-content: space-between;
}

.selector {
  width: 100%;
  max-height: 50px;
  overflow: auto;
}

.formTitle {
  @include wrapperTitle;
}

@media only screen and (max-width: 812px) {
  .tableAttributes {
    width: 100%;
    overflow: auto;
  }

  .tableAttributesWrapper {
    max-width: 100%;
    min-width: 100%;
    margin: 0;
  }

  .newAttributeWrapper {
    width: 100%;
    margin-top: 15px;
  }

  .formNewAttribute {
    width: 250px;
  }
}
